import { NodeProps, Position } from 'reactflow';
import Node from '../Node';
import FollowUpMessageHandle from '../../handles/FollowUpMessageHandle';
import styled from 'styled-components';
import { useAtomValue } from 'jotai';
import { activeLanguageAtom } from '../../../atoms/atoms';
import Row from '../components/NodeRow';
import { useMemo } from 'react';

export type EndingNodeData = {
	directus_id: number | null
	translations: EndingTranslations,
	achieved: boolean,
}

export type EndingTranslations= {[lng: string] : {
	directus_id: number | null,
	description: string,
	ellie_description: string
}}


const EndingNode = (props: NodeProps<EndingNodeData>) => {

	const language = useAtomValue(activeLanguageAtom);
	const targetHandleId = useMemo(() => props.id ?`target-${props.id}` : undefined, [props.id]);

	const description = useMemo(() => {
		const newText = props.data.translations[language];
		if(newText) return newText.description;
		else return 'no translation found';
	},[props.data.translations, language]);

	return (
		<StyledNode $achieved={props.data.achieved} {...props} title={'ending - '+(props.data.directus_id ? props.data.directus_id.toString() : '')}>
			<Row><p>{description}</p></Row>
			<FollowUpMessageHandle id={targetHandleId} type="target" position={Position.Left}/>
		</StyledNode>
	);
};

const StyledNode = styled(Node)<{$achieved: boolean}>`
	max-width: 200px;
	background-color: ${p => p.$achieved  ? p.theme.colors.correct : p.theme.colors.danger};
`;
 
export default EndingNode;