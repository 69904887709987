import { useAtom, useAtomValue } from 'jotai';
import { CustomNode, NodeData, edgesAtom, nodesAtom } from '../atoms/flow';
import { createEndingTranslationsInDirectus, createMessageTranslationInDirectus, createScenarioTranslationInDirectus } from '../tools/directusActions/create';
import {  updateEndingTranslationsInDirectus, updateMessageTranslationInDirectus, updateNodeInDirectus, updateScenarioTranslationsInDirectus } from '../tools/directusActions/update';
import { deleteNodeInDirectus } from '../tools/directusActions/delete';
import { useDeleteEgde } from './edgeHooks';
import { Node } from 'reactflow';
import { ObjectEntries, activeLanguageAtom } from '../atoms/atoms';
import { MessageNodeData } from '../Editor/nodes/MessageNode/MessageNode';
import { EndingNodeData } from '../Editor/nodes/EndingNode/EndingNode';
import { StickynoteNodeData } from '../Editor/nodes/StickynoteNode/StickynoteNode';
import { IntroductionNodeData } from '../Editor/nodes/IntroductionNode/IntroductionNode';
import { SceneswapNodeData } from '../Editor/nodes/SceneswapNode/SceneswapNode';

export type changeOptions = ObjectEntries<IntroductionNodeData> |  ObjectEntries<MessageNodeData & {node_data: NodeData}> 
| ObjectEntries<EndingNodeData> | ObjectEntries<StickynoteNodeData> | ObjectEntries<SceneswapNodeData>

export const useUpdateNodeData = () => {
	const [, setNodes] = useAtom(nodesAtom);
	const updateNodeTranslationsData = useUpdateNodeTranslationsData();

	return (nodeId: string, change: changeOptions, alsoUpdateDirectus?: boolean) => {
		setNodes((nodes) => nodes.map(node => {
			if (node.id === nodeId && node.type) {
				if(change.field === 'translations') updateNodeTranslationsData(node, change.newValue);
				else if(alsoUpdateDirectus != false && node.data.directus_id) updateNodeInDirectus(node.type, node.data.directus_id, change);
				node.data = { ...node.data, [change.field]: change.newValue === undefined ? null : change.newValue };	//set data in flows
			}
			return {...node};
		}));
	};
};

export const useUpdateNodeTranslationsData = () => {
	const [, setNodes] = useAtom(nodesAtom);
	const [language, ] = useAtom(activeLanguageAtom);

	return (updatedNode: CustomNode, newData: any) => {
		const updateTranslationId = (id: number) => {
			setNodes((nodes) => nodes.map(node => {
				if (node.id === updatedNode.id) {
					newData[language].directus_id = id;
					node.data = { ...node.data, 'translations': newData };	//set data in flows
				}
				return {...node};
			}));		
		};

		if(updatedNode.type === 'message'){
			if(updatedNode.data.translations[language]){ //update existing message translation item 
				const translationsId = updatedNode.data.translations[language].directus_id;
				translationsId && updateMessageTranslationInDirectus(translationsId, newData[language]); 
			}else
				updatedNode.data.directus_id && createMessageTranslationInDirectus(updatedNode.data.directus_id, newData, language).then((request: any) => {
					//create message translation item and set id in data
					updateTranslationId(request.id);
				}); 
		}	
		else if(updatedNode.type === 'ending'){
			if(updatedNode.data.translations[language]){ //update existing ending translation item 
				const translationsId = updatedNode.data.translations[language].directus_id;
				translationsId && updateEndingTranslationsInDirectus(translationsId, newData[language]); 					
			} else
				updatedNode.data.directus_id && createEndingTranslationsInDirectus(updatedNode.data.directus_id, newData, language).then((request: any) => {
					//create ending translation item and set id in data
					updateTranslationId(request.id);
				}); 
		}
		else if(updatedNode.type === 'introduction'){
			if(updatedNode.data.translations[language]){ //update existing ending translation item 
				const translationsId = updatedNode.data.translations[language].directus_id;
				translationsId && updateScenarioTranslationsInDirectus(translationsId, newData[language]); 					
			} else
				updatedNode.data.directus_id && createScenarioTranslationInDirectus(updatedNode.data.directus_id, newData, language).then((request: any) => {
					//create scenario translation item and set id in introduction data
					updateTranslationId(request.id);
				}); 
		}
	};
};

export const useUpdateNodeValues = () => {
	const [, setNodes] = useAtom(nodesAtom);

	return (nodeId: string, change: ObjectEntries<Node>) => {
		setNodes((nodes) =>
			nodes.map(node => {
				if (node.id === nodeId && change) 
					node = {...node, [change.field]: change.newValue};
				return node;
			}));
	};
};

export const useGetNodeWithId = <T extends CustomNode = CustomNode>() => {
	// @ts-expect-error typing with generics works, but Atoms are acting up. Needs more research on our part.
	const nodes = useAtomValue<T[]>(nodesAtom);

	return (requestedId: string) => nodes.find((node) => node.id === requestedId);
};

export const useDeleteNode = () => {
	const [, setNodes] = useAtom(nodesAtom);
	const [edges,] = useAtom(edgesAtom);
	const deleteEgde = useDeleteEgde();
	const getNodeWithId = useGetNodeWithId();

	return (requestedNodeId: string) => {

		const node = getNodeWithId(requestedNodeId);
		if(!node || node.type === 'introduction') return;
		const connected = edges.filter((edge) => edge.target === requestedNodeId || edge.source === requestedNodeId);

	
		const executeDelete = () => {
			deleteNodeInDirectus(node);
			setNodes((nodes) =>
				nodes.filter(node => node.id != requestedNodeId)
			);
		};

		if (connected.length != 0) //check if this message is connected to other nodes
			connected.map(async (edge, index) => {
				//delete edges before deleting node
				deleteEgde(edge).then(() => {
					if (index === connected.length - 1) executeDelete();
				});
			});
		else
			executeDelete();
	};
};