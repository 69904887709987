// this file contains logic related to flows state

import { atom } from 'jotai';
import { Edge, Node } from 'reactflow';
import { Auditing, Schema } from '../tools/directus';
import { Scenario } from './menu';
import { EndingNodeData } from '../Editor/nodes/EndingNode/EndingNode';
import { MessageNodeData } from '../Editor/nodes/MessageNode/MessageNode';
import { StickynoteNodeData } from '../Editor/nodes/StickynoteNode/StickynoteNode';
import { IntroductionNodeData } from '../Editor/nodes/IntroductionNode/IntroductionNode';
import { SceneswapNodeData } from '../Editor/nodes/SceneswapNode/SceneswapNode';


// Node Types

export type CustomNode = MessageNode | EndingNode | StickynoteNode | IntroductionNode | SceneswapNode;
export type MessageNode = Node<MessageNodeData, 'message'>;
export type EndingNode = Node<EndingNodeData, 'ending'>;
export type StickynoteNode = Node<StickynoteNodeData, 'stickynote'>;
export type IntroductionNode = Node<IntroductionNodeData, 'introduction'>;
export type SceneswapNode = Node<SceneswapNodeData, 'sceneswap'>;


export type NodeType = 'message' | 'ending' | 'stickynote' | 'introduction' | 'sceneswap'

export const collections: {[key in NodeType] : keyof Schema} = {
	message: 'product_2_dialogues_messages',
	ending: 'product_2_scenarios_endings',
	stickynote: 'product_2_stickynotes',
	introduction: 'product_2_scenarios',
	sceneswap: 'product_2_scene_swaps',
};

// atoms
export const flowDataAtom = atom<FlowData | undefined>(undefined);
export const nodesAtom = atom<CustomNode[]>([]);
export const edgesAtom = atom<Edge[]>([]);

// types

export type FlowData = {
	scenario: Scenario,
	scenarioTranslations: { [id: number]: ScenarioTranslations },
	messages: { [id: number]: Message; },
	messagesTranslations: { [id: number]: MessageTranslation; },
	answers: { [id: number]: MessageAnswer; },
	answerTranslations: { [id: number]: MessageAnswerTranslation; },
	endings: { [id: number]: Ending; },
	endingsTranslations: { [id: number]: EndingTranslation; },
	stickynotes: { [id: number]: Stickynote; };
	sceneswaps: { [id: number]: Sceneswap }

};

export type ScenarioTranslations =  {
	id: number,
	product_2_scenarios_id: number,
	product_2_languages_code: string,
	name: string
	description: string
	ellie_description: string
}

export type Message = Auditing & {
	id: number;
	scenario_id: number;
	message_type: 0 | 1;
	speaking_character: number,
	answers: number[];
	ending: number | null;
	reset: boolean;
	ellie_emotion: 'happy' | 'thinking'
	translations: number[];
	follow_up_message: number | null,
	node_data: NodeData;
	events: string;
};

export type Stickynote = Auditing & {
	id: number;
	scenario_id: number;
	text: string
	color: string
	node_data: NodeData;
};

export type Sceneswap = {
	id: number
	scenario_id: number;
	node_data: NodeData;
	source_message_ids: number[]
	target_message_id: number | null
	scene_id: string
};

export type NodeData = {
	positions: {
		x: number,
		y: number;
	};
};

export type MessageTranslation = {
	id: number;
	product_2_dialogues_messages_id: number | null;
	product_2_languages_code: string;
	text: string;
	overwrite_name: string;
};

export type MessageAnswer = Auditing & {
	id: number;
	name: string;
	message_id: number | null;
	translations: number[];
	follow_up_message: number | null;
	correctness: 0 | 1 | 2 | null;
	conditional_requirement: number | null;
};

export type MessageAnswerTranslation = {
	id: number;
	product_2_dialogues_messages_answers_id: number;
	product_2_languages_code: string;
	text: string;
	feedback_title: string;
	feedback_body: string;
};

export type GameEvent = { direct?: boolean, fifo?: boolean; } & EventActions;

export type EventActions = AnimationAction | MoodAction | SceneAction | MessageAction;

export type SceneAction = {
	type: 'change-scene';
	scene_id: string
};

export type MessageAction = {
	type: 'set-message'
	message_id: string
};

export type AnimationAction = {
	type: 'set-character-animation';
	object_id: number | null, animation: string;
};

export type MoodAction = {
	type: 'set-character-mood';
	object_id: number | null, mood: string;
};

export type Ending = Auditing & {
	id: number,
	scenario_id: number,
	translations: number[];
	achieved: boolean,
	node_data: NodeData;
};

export type EndingTranslation = {
	id: number,
	description: string,
	ellie_description: string,
	product_2_languages_code: string;
	product_2_scenarios_endings_id: number;
};